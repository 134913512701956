<script setup lang="ts">
</script>

<template>
  <div class="container lenn-overlay">
    <div class="content">
      <div class="header-title">
        <h1 v-html="$t('bigVerloopTitle')"></h1>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .content {
    max-width: 1040px;
    margin: auto;
    height: 800px;
    min-height: 100vh;
    max-height: 100vh;
    display: flex;
  }

  @keyframes content-anim {
    0% {
      min-height: 100vh;
      max-height: 100vh;
    }
    100% {
      min-height: 300px;
      height: 400px;
      max-height: 400px;
    }
  }

  .header-title {
    color: white;
    align-self: flex-end;
    margin: 0px 20px 40px 40px;
  }

  @media (max-width: 600px) {
    .header-title {
      font-size: 40px;
      line-height: 40px;
      margin: 0px 20px 16px 16px;
    }

    .content {
      min-height: 80vh;
    }
  }

  @media (min-width: 600px) {
    .content {
      animation-delay: 1.0s;
      animation-iteration-count: 1;
      animation-name: content-anim;
      animation-duration: 4.9s;
      /* animation-timing-function: ease-in; */
      animation-fill-mode: forwards;
    }
  }
</style>