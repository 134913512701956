<script setup lang="ts">
  import BigVerloopVlak from '../components/BigVerloopVlak.vue';
  import VlakImageBackground from '../components/VlakImageBackground.vue';
  import VlakBullets from '../components/VlakBullets.vue';
  import CenterQuote from '../components/CenterQuote.vue';
  import TwoVlakRowImageLeft from '../components/TwoVlakRowImageLeft.vue';
  import FourButtons from '../components/FourButtons.vue';
  import ZorgkaartReviews from '../components/ZorgkaartReviews.vue'
  import { useMeta } from 'vue-meta'

  useMeta({
    title: "Jouw psycholoog in de buurt – fysiek én online | Lenn",
    meta: [
      {
        name: "image", property: "og:image", content: "https://lenn-zorgt.nl/assets/OG_Image.png"
      },
      {
        name: 'description', property: 'og:description', content: 'Binnen enkele dagen een gesprek met een ervaren psycholoog. In onze spreekkamer én online. In Rotterdam, Delft, Lansingerland en Groningen. Regel het direct.'
      }
    ],
    og: {
      url: "https://lenn-zorgt.nl/assets/OG_Image.png",
      description: 'Binnen enkele dagen een gesprek met een ervaren psycholoog. In onze spreekkamer én online. In Rotterdam, Delft, Lansingerland en Groningen. Regel het direct.'
    }
  })
</script>

<template>
  <metainfo>
    <template v-slot:title="{ content }">{{ content }}</template>
  </metainfo>
  <BigVerloopVlak/>
  <TwoVlakRowImageLeft>
    <template #left>
      <picture>
        <source type="image/webp" media="(min-width: 600px)" srcset="@/assets/images/pink-girl_landscape.webp">
        <source type="image/webp" media="(min-width: 300px)" srcset="@/assets/images/pink-girl_landscape_mobile.webp">
        <img loading="lazy" class="TwoVlakRowImage" src="@/assets/images/pink-girl_landscape.png"/>
      </picture>
    </template>
    <template #right>
      <div class="first-twovlak-right-text">
        <h2>{{ $t("homeView.first.title") }}</h2>
        <p v-html="$t('homeView.first.content')"></p>
      </div>
    </template>
  </TwoVlakRowImageLeft>
  <CenterQuote/>
  <VlakImageBackground hasCta="approach"/>
  <VlakBullets/>
  <TwoVlakRowImageLeft styles="lenn-overlay">
    <template #left>
      <picture>
        <source type="image/webp" media="(min-width: 600px)" srcset="@/assets/images/Lenn-6334b.webp">
        <source type="image/webp" media="(min-width: 300px)" srcset="@/assets/images/Lenn-6334b.webp">
        <img loading="lazy" class="TwoVlakRowImage" src="@/assets/images/Lenn-6334b.jpg">
      </picture>
    </template>
    <template #right>
      <div class="twovlak-right-text">
        <p v-html="$t('homeView.second.content')"></p>
      </div>
    </template>
  </TwoVlakRowImageLeft>
  <FourButtons/>
  <ZorgkaartReviews/>
</template>

<style scoped>
  .cta {
    position: absolute;
    z-index: 1;
    bottom: 0;
    margin-bottom: 40px;
    left: calc(100% - 220px);
    display: flex;
    width: 210px;
  }

  .first-twovlak-right-text, .twovlak-right-text {
    max-width: 520px;
  }

  .first-twovlak-right-text h2 {
    color: var(--red);
  }

  .twovlak-right-text {
    color: white;
    font-weight: 100;
    margin-top: 30px;
    /* margin: 30px 30px 30px 30px; */
  }

  @media (max-width: 600px) {
  }
</style>