<script setup lang="ts">
  const closeAndSetCookie = () => {
    let d = new Date();
      d.setTime(d.getTime() + 365 * 24 * 60 * 60 * 1000);
      let expires = "expires=" + d.toUTCString();
      document.cookie =
        "cookie=seen;" + expires + ";path=/;secure";

    document.getElementById("cookie-banner")?.remove()
  }

  const cookieSet = document.cookie.includes("seen")
</script>

<template>
  <div v-if="!cookieSet" id="cookie-banner" class="cookie-banner">
    <div class="cookie-content">
      <div class="cookie-text">
        <p>Deze website maakt gebruik van cookies om de website te optimaliseren door statistieken bij te houden. Door verder te gaan op onze website, ga je akkoord met het gebruik van cookies. Je kunt de cookie-instellingen op elk moment wijzigen. Raadpleeg ons <RouterLink to="/cookieverklaring">cookieverklaring</RouterLink> voor meer informatie over hoe wij omgaan met je gegevens.</p>
      </div>
      <div class="cookie-button">
        <button @click="closeAndSetCookie" class="menu-btn">sluiten</button>
      </div>
    </div>
  </div>
</template>

<style scoped>
  p {
    font-size: 18px;
    line-height: 18px;
  }
  .cookie-content {
    background-color: white;
    margin: 50px 50px 150px 60%;
    padding: 24px;
    border-radius: 10px;
    border: 1px solid rgba(244, 244, 244, 1);
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.15);
  }
  .cookie-banner {
    width: 100vw;
    position: fixed;
    bottom: 0;
    z-index: 200;
    left: 0;
  }

  .menu-btn {
    color: white;
    background-color: var(--red);
    border: none;
    font-size: 18px;
    border-radius: 3px;
    padding: 7px 8px 7px 8px;
    margin-top: 2px;
    transition: all .2s ease;
    border: 2px solid var(--red);
    -webkit-transition: all .2s ease;
    cursor: pointer;
  }

  @media (max-width: 800px) {
    .cookie-content {
      margin: 24px;
    }

    p {
    font-size: 16px;
    line-height: 16px;
  }
  }
</style>