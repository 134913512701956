<script setup lang="ts">
  import { onMounted } from 'vue'

  onMounted(() => {
    addZorgkaartJSToPage()
  })

  function addZorgkaartJSToPage() {
    let zorgkaartJS = document.createElement('script')
    zorgkaartJS.setAttribute('src', "https://www.zorgkaartnederland.nl/zkn/widget/score/t/3/v/2/organisatie/10082989")
    zorgkaartJS.setAttribute('async', "async")
    document.head.appendChild(zorgkaartJS)
  }
</script>

<template>
  <div class="zorgkaart-container">
    <div id="zkn-score-widget-10082989">
      <div class="zkn-widget-holder zkn-s336">
        <div class="zkn-widget">
          <div class="zkn-widget__header">
            <a href="https://www.zorgkaartnederland.nl" target="_blank" class="zkn-widget__a zkn-widget__header-logo-left">
              <img class="zkn-widget__header-logo-left-img" src="https://www.zorgkaartnederland.nl/bundles/zkncontent/images/elements/logo_zkn.svg" alt="Zoek, vind en waardeer zorgaanbieders op ZorgkaartNederland.nl"></a>
              <a href="https://www.patientenfederatie.nl/" target="_blank" class="zkn-widget__a zkn-widget__header-logo-right">
                <img class="zkn-widget__header-logo-right-img" src="https://www.zorgkaartnederland.nl/bundles/zkncontent/images/elements/logo_npcf_mobile.svg" alt="Patiëntenfederatie Nederland">
              </a>
            </div>
            <div class="zkn-widget__body">
              <a class="zkn-widget__a" href="https://www.zorgkaartnederland.nl/zorginstelling/ggz-lenn-rotterdam-10082989" target="_blank">Lenn</a> is gewaardeerd op ZorgkaartNederland.
            </div><div class="zkn-widget__footer">
              <a class="zkn-widget__a" href="https://www.zorgkaartnederland.nl/zorginstelling/ggz-lenn-rotterdam-10082989" target="_blank">Bekijk alle waarderingen</a> of <a class="zkn-widget__a" href="https://www.zorgkaartnederland.nl/zorginstelling/ggz-lenn-rotterdam-10082989" target="_blank">plaats een waardering</a>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>

<style scoped>
.zorgkaart-container {
  width: 400px;
  margin: auto;
  margin-bottom: 100px;
}
</style> 