<script setup lang="ts">
  import { ref } from 'vue';
  import { RouterLink } from 'vue-router';
  import { Row, Col } from 'ant-design-vue';

  const visible = ref<boolean>(false);
  const showDrawer = () => {
    visible.value = true;
  };
  const onClose = () => {
    visible.value = false;
  };
  const year = new Date().getFullYear()


</script>

<template>
  <a @click="showDrawer" class="menu-btn">
    menu
  </a>
  <ADrawer width="100%" placement="right" :closable="false" :visible="visible" @close="onClose">
    <Row style="height: 100%">
      <Col :xs="{ span: 24, order: 2}" :sm="{ span: 8, order: 1}">
        <div class="left-container">
          <div class="left-top-content">
            <img class="logo" src="@/assets/lenn_logo_gradient.svg"/>
            <RouterLink to="/aanmelden"><button class="menu-btn-primary"><span @click="onClose">{{ $t("nav.signup") }}</span></button></RouterLink>
            <a href="https://lenn.minddistrict.nl/" target="_blank"><button class="menu-btn-primary last"><span @click="onClose">{{ $t("nav.login") }}</span></button></a>
          </div>
          <div class="left-bottom-contact">
            <h2>{{ $t("nav.contact.title") }}</h2>
            <p>{{ $t("nav.contact.question") }}</p>
            <p>{{ $t("nav.contact.call") }}<br/>
            <a href="https://wa.me/31649682978" class="whatsapp" target="_blank">WhatsApp</a> via 06-496 829 78</p>
            <div class="line"></div>
            <div class="small">Lenn ©{{ year }}</div>
          </div>
        </div>
      </Col>
      <Col :xs="{ span: 24, order: 1}" :sm="{ span: 16, order: 2}">
        <div class="container lenn-overlay">
          <a @click="onClose" class="menu-btn right">{{ $t("nav.close") }}</a>
          <div class="content-container">
            <p><RouterLink to="/"><span @click="onClose">{{ $t("nav.home") }}</span></RouterLink></p>
            <p><RouterLink to="/over-lenn"><span @click="onClose">{{ $t("nav.about") }}</span></RouterLink></p>
            <p><RouterLink to="/hoe-werkt-lenn"><span @click="onClose">{{ $t("nav.howDoesItWork") }}</span></RouterLink></p>
            <p><RouterLink to="/voor-wie"><span @click="onClose">{{ $t("nav.forWho") }}</span></RouterLink></p>
            <p><RouterLink to="/verwijzers"><span @click="onClose">{{ $t("nav.forRefferers") }}</span></RouterLink></p>
            <p><RouterLink to="/tarieven-en-vergoedingen"><span @click="onClose">{{ $t("nav.tarifs") }}</span></RouterLink></p>
            <p><RouterLink to="/contact"><span @click="onClose">{{ $t("nav.contactsLocations") }}</span></RouterLink></p>
            <p><RouterLink to="/werkgevers"><span @click="onClose">{{ $t("nav.forEmployers") }}</span></RouterLink></p>
            <p><RouterLink to="/werken-bij-lenn"><span @click="onClose">{{ $t("nav.workForLenn") }}</span></RouterLink></p>
            <p><RouterLink to="/nieuws"><span @click="onClose">{{ $t("nav.news") }}</span></RouterLink></p>
          </div>
        </div>
      </Col>
    </Row>
  </ADrawer>  
</template>

<style scoped>
  .left-container {
    width: 100%;
    height: 100%;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .left-top-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .menu-btn-primary {
    border: 1px solid var(--black);
  }

  .menu-btn-primary:first-child {
    margin-bottom: 10px;
  }

  .menu-btn-primary:hover {
    color: white;
    background-color: var(--black);
  }

  .logo {
    height: 79px;
    width: 300px;
    max-width: 80%;
    margin-bottom: 50px;
  }

  .line {
    height: 1px;
    background-color: var(--black);
    margin-bottom: 12px;
  }

  .whatsapp {
    text-decoration: underline;
    text-decoration-color: black;
    color: var(--black);
  }

  .menu-btn {
    color: white;
    background-color: var(--black);
    border: none;
    font-size: 25px;
    border-radius: 6px;
    padding: 7px 12px 7px 7px;
    margin-top: 5px;
    transition: all .2s ease;
    border: 2px solid var(--black);
    -webkit-transition: all .2s ease;
  }

  .right {
    margin-left: auto;
    position: absolute;
    right: 30px;
  }

  .menu-btn:hover {
    background: transparent;
    color: var(--black);
    border: 2px solid var(--black);
    transition: all .6s ease;
    -webkit-transition: all .6s ease;
  }

  .container {
    height: 100%;
    width: 100%;
  }

  .content-container {
    padding: 30px 80px;
    color: white;
    font-weight: 100;
  }

  .content-container a {
    color: white;
  }

  .content-container p {
    font-size: 40px;
    line-height: 37px;
  }

  @media (max-width: 600px) {
    .content-container {
      padding: 30px 30px;
      color: white;
    }

    .content-container p {
      font-size: 28px;
      line-height: 28px;
    }

    .menu-btn {
      font-size: 20px;
      margin-left: 4px;
    }
  }
</style>