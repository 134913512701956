<script setup lang="ts">
  import { RouterLink } from 'vue-router';
  import { Row, Col } from 'ant-design-vue';

  const year = new Date().getFullYear()
</script>

<template>
  <div class="lenn-overlay">
    <ALayoutFooter class="footer">
      <Row>
        <Col :xs="24" :sm="12">
          <h2>{{ $t("footer.contact") }}</h2>
          <p v-html="$t('footer.questionContent')"></p>
          <p>{{ $t("footer.callUs")}} 085-8228262 <br/>
            <a href="https://wa.me/31649682978" style="margin: 0px; text-decoration: underline;" target="_blank">WhatsApp</a> via 06-496 829 78</p>
        </Col>
        <Col :xs="24" :sm="12">
            <img class="footer-logo" src="../assets/lenn_logo_full.svg"/>
        </Col>
      </Row>
    </ALayoutFooter>
  </div>
  <div class="alt-menu">
    <div class="alt-menu-container">
      <span class="alt-menu-item"><RouterLink to="/">{{ $t("nav.home") }}</RouterLink></span>
      <span class="alt-menu-item"><RouterLink to="/over-lenn">{{ $t("nav.about") }}</RouterLink></span>
      <span class="alt-menu-item"><RouterLink to="/hoe-werkt-lenn">{{ $t("nav.howDoesItWork") }}</RouterLink></span>
      <span class="alt-menu-item"><RouterLink to="/voor-wie">{{ $t("nav.forWho") }}</RouterLink></span>
      <span class="alt-menu-item"><RouterLink to="/verwijzers">{{ $t("nav.forRefferers") }}</RouterLink></span>
      <span class="alt-menu-item"><RouterLink to="/tarieven-en-vergoedingen">{{ $t("nav.tarifs") }}</RouterLink></span>
      <span class="alt-menu-item"><RouterLink to="/contact">{{ $t("nav.contactsLocations") }}</RouterLink></span>
      <span class="alt-menu-item"><RouterLink to="/werkgevers">{{ $t("nav.forEmployers") }}</RouterLink></span>
      <span class="alt-menu-item"><RouterLink to="/werken-bij-lenn">{{ $t("nav.workForLenn") }}</RouterLink></span>
      <span class="alt-menu-item"><RouterLink to="/nieuws">{{ $t("nav.news") }}</RouterLink></span>
    </div>
  </div>
  <div class="links-container">
    <div class="year">Lenn ©{{ year }}</div>
    <div class="links small">
        <RouterLink to="/privacyverklaring">{{ $t("nav.footer.privacyStatement") }}</RouterLink>
        <RouterLink to="/cookieverklaring">{{ $t("nav.footer.cookieStatement") }}</RouterLink>
        <a href="/assets/Kwaliteitsstatuut.pdf" target="_blank">{{ $t("nav.footer.qualitySt") }}</a>
        <a href="/assets/Klachtenregeling.pdf" target="_blank">{{ $t("nav.footer.complaints") }}</a>
        <RouterLink to="/algemene-voorwaarden">{{ $t("nav.footer.conditionsTerms") }}</RouterLink>
      </div>
  </div>
</template>

<style scoped>
  .footer-logo {
    width: 200px;
    object-fit: contain;
    position: absolute;
    bottom: 0;
    right: 0;
  }
  .footer {
    max-width: 100%;
    width: 1040px;
    margin: 0px auto;
    color: white;
  }
  .footer h2 {
    color: white;
  }

  .footer a {
    margin: 0px 8px;
    color: white;
  }

  .links-container {
    width: 1040px;
    margin: auto;
    display: flex;
    background-color: white;
    max-width: 100%;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 10px;
    padding-bottom: 10px;
    justify-content: space-between;
  }

  .year {
    float: left;
  }
  .links {
    float: left;
    text-align: end;
    color: var(--black);
    /* color: black; */
  }

  .links a {
    color: var(--black);
    margin: 5px;
  }

  .ant-layout-footer {
    background-color: transparent;
    padding-left: 40px;
    padding-right: 40px;
  }

  .alt-menu {
    background-color: var(--magenta);
    width: 100%;
    display: flex;
    align-items: start;
    /* max-width: 1040px; */
    /* margin: auto; */
  }

  .alt-menu-item {
    max-width: 1040px;
    margin: auto;
    /* margin: 10px; */
    /* background-color: var(--red); */
    border-radius: 4px;
    padding: 4px;
    color: white;
  }

  .alt-menu-container {
    max-width: 1040px;
    margin: auto;
    margin-top: 40px;
    margin-bottom: 40px;
    text-align: center;
  }

  .alt-menu-item a {
    font-size: 1.4em;
    color: white;
    text-decoration: underline rgb(254, 254, 254, 0.3);
  }

  @media (max-width: 800px) {
    .footer {
      margin: 0px auto;
      text-align: center;
      justify-content:center;
      display: block;
    }

    .alt-menu {
      width: auto;
      display: block;
    }

    .alt-menu-container {
      margin-top: 2px;
      margin-left: 40px;
      margin-bottom: 20px;
      text-align: left;
    }

    .year {
      float: none;
      align-items: start;
      display: flex;
      padding: 10px 0px;
    }

    .footer-logo {
      position: relative;
    }

    .links a, span {
      margin: 16px 0px;
      display: flex;
      text-align: left;
    }

    .links-container {
      margin-top: 24px;
      display: block;
    }
  }
</style>